.card {
    $c: &;

    span {
        transition: color .2s ease-in-out;
    }

    &__img {
        transition: transform .2s $cubicBezier;
        transform: scale(1);
    }

    @at-root {
        a#{&} {
            &:hover {
                span {
                    @apply text-gray-600;
                }
            }
        }
    }
}