.hero {
    @apply w-screen block;
    height: 50vh;
    min-height: 350px;
    position: relative;
    max-height: 450px;

    @screen lg {
        min-height: 400px;
        max-height: 600px;
    }

    @screen xl {
        min-height: 500px;
        max-height: 700px;
    }

    &--full {
        height: 80vh;
        max-height: 80vh;
    }

    &__img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        object-position: center;
        overflow: hidden;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &-body {
        @apply max-w-sm mt-12;
        text-align: center;
        position: relative;
        width: 100%;
        z-index: 2;

        @screen lg {
            @apply mt-16;
        }

        @screen xl {
            @apply max-w-full w-4/12;
        }

        &__bg {
            position: relative;
            z-index: 1;
        }

        &__inner {
            @apply pt-2;
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}