html {
    scroll-behavior: smooth
}
    
body {
    overflow-x: hidden;
    
    &.menu-is-active {
        @apply overflow-hidden;
    }
}

.site {
    opacity: 1;
    transition: opacity .3s ease-in-out;
    min-height: 100vh;
    padding-top: 4rem;

    @screen lg {
        padding-top: 5rem;
    }

    &.menu-is-active {
        opacity: .5;
    }
}