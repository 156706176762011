.container {
    @apply px-4 mx-auto max-w-full;
    min-width: 300px;

    @screen xs {
        max-width: 1600px;

        &--slim {
            @apply max-w-3xl
        }

        &--checkout {
            @apply max-w-6xl
        }
    }

    @screen md {
        @apply px-16;

        &--slim {
            @apply px-6;
        }
    }

    @screen lg {
        @apply px-32;

        &--slim {
            @apply px-16;
        }
    }

    @screen xl {
        @apply px-4;

        &--slim {
            @apply px-6;
        }
    }
}