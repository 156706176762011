.cookie-bar {
    @apply fixed bottom-0 text-base left-0 bg-background p-4 w-full border-t;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s ease-in-out 0s, visibility .0s ease .3s;

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s ease .0001s, visibility .0s ease .0s;
    }
    
    @screen xs {
        @apply px-6;
    }

    @screen sm {
        @apply w-80 mb-6 ml-6 p-6 border;
    }
}