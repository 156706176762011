.sticky-el {
    @screen sm {
        position: sticky;
    }

    &--bottom {
        @screen sm {
            bottom: 1rem;
        }
        
        @screen lg {
            bottom: 2rem;
        }
    }

    &--top {
        @screen sm {
            top: 6rem;
        }
    
        @screen lg {
            top: 8rem;
        }
    }
}