.form {
    &-wrap {
        max-width: 800px;
        border-radius: .5rem;
        margin: auto;
        background: color('cream');
        padding: 1rem;

        + .form-wrap {
            margin-top: 2rem;
        }

        @screen md {
            padding: 2rem;
        }

        @screen lg {
            padding: 3rem;
        }

        @screen xl {
            padding: 4rem;
        }
    }

    &__row {
        @screen lg {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
        }

        &:last-child {
            margin-bottom: -1rem;
        }
    }

    &__col {
        margin-bottom: 1rem;

        @screen lg {
            flex: 1;
            display: flex;

            &--align-right {
                justify-content: flex-end;
            }

            &--align-spread {
                justify-content: space-between;
            }
        }
    }

    &__section {
        > * {
            &:last-child {
                margin-bottom: 3rem;
            }
        }
    }
}