.highlight {
    $h: &;

    &__title {
        @apply inline;
    }

    &:hover {
        #{$h}__title {
            @apply border-b border-gray-700;
        }
    }
}