.row {
    $r: &;
    position: relative;

    > * {
        position: relative;
        z-index: 1;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: 0;
    }
    
    &:not(.row--slim):not(.row--footer):not(.row--slides) {
        @apply py-6;

        @screen sm {
            @apply py-12;
        }
        @screen md {
            @apply py-16;
        }
        @screen lg {
            @apply py-20;
        }
        @screen xl {
            @apply py-24;
        }
    }

    &--footer {
        @apply py-4;
    }

    &--slim {
        @apply py-6;
    }

    @each $name, $hex in $colors {
        &--#{$name} {
            @include text-color($name);
            
            &:before {
                background-color: $hex;
            }

            + .row--#{$name} {
                padding-top: 0 !important;
            }
        }
    }
}