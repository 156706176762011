

#paymentForm,
.stripe-charge-form {
    > div {
        input,
        textarea {
            @apply border border-gray-700;
            width: 100%;
            background: transparent;
            padding: 0 1rem;
            height: 3rem;
            display: block;
            appearance: none;
        }
        
        .item {
            @apply mb-4;
        }
    }
}

.stripe-charge-form {
    @apply w-full #{!important};
}

.StripeElement {
    @apply w-full border border-gray-700 relative mt-12 #{!important};
    padding: 1rem !important;

    &:before {
        @apply absolute left-0 font-sans uppercase text-sm mb-2;
        content: 'Card details';
        letter-spacing: 2px;
        bottom: 100%;
    }

    @screen sm {
        @apply p-4;
    }
}

.card-holder {
    @apply mb-8;
    
    @screen sm {
        @apply mb-12;
    }

    legend {
        @apply font-sans uppercase text-sm mb-2;
        letter-spacing: 2px;
    
        @screen sm {
            @apply mb-3;
        }
    }
}