// @import url("//hello.myfonts.net/count/3c439d");
// @import url("//use.typekit.net/iuo8bpo.css");

@font-face {
    font-family: 'EngraversGothicBT-Regular';
    text-transform: uppercase;
    font-display: swap;
    src:
        url('../fonts/engravers-gothic/font.woff2') format('woff2'),
        url('../fonts/engravers-gothic/font.woff') format('woff');
}