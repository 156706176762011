.social-icon {
    transition: background .2s ease-in-out;
    height: 3rem;
    width: 3rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: color(white);
    background: color(primary);

    &:hover {
        background: color(primary);
    }

    @screen md {
        height: 2.8rem;
        width: 2.8rem;
    }

    @each $name, $hex in $social {
        &--#{$name} {
            background-color: $hex;
        }
    }
}