// Plyr overrides
.plyr--paused {
    &:not(.plyr--stopped) {
        .plyr__poster {
            display: none;
        }
    }
}

.plyr--video .plyr__controls {
    width: 100%;
    padding: .5rem;
}

.plyr--video .plyr__time {
    text-shadow: none;
}


// Colour theming
:root {
    --plyr-color-main: #cfdc4a;
}