.promo {
    @apply w-full relative block;

    &__img {
        z-index: 10;

        &--short {
            @screen sm {
                @apply h-80;

                img {
                    @apply h-full w-full;
                    object-fit: cover;
                }
            }
            @screen md {
                @apply h-auto;
            }
        }
    }

    &__title {
        @apply absolute;

        &--top {
            @apply top-0 left-0 w-full p-4 bg-white;

            @screen xs {
                @apply px-6 py-8;
            }

            @screen lg {
                @apply px-8 py-10;
            }

            svg {
                transform: translate3d(0,-1px,0);
            }
        }

        &--cartouche {
            width: calc(100% - 2rem);
            top: 50%;
            left: 50%;
            transform: translate3d(-50%,-50%,0);

            @screen lg {
                width: calc(100% - 4rem);
            }
        }
    }
}