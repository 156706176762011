.accordion {
    position: relative;
    @apply border-gray-700 border-t;

    &-wrap {
        @apply border-gray-700 border-b;
    }

    &__heading {
        @apply py-3;
        position: relative;
        cursor: pointer;
        list-style-type: none;

        &:focus {
            outline: none;
        }

        &:focus,
        &:hover {
            .accordion__title {
                @apply border-b;
            }
        }

        &::-webkit-details-marker {
            display: none;
        }

        &::marker {
            display: none;
        }
    }

    &__title {
        @apply border-gray-700;
        position: relative;
        display: inline-block;
    }

    &__body {
        overflow: hidden;
        
        > * {
            @apply pb-6;

            @screen lg {
                @apply pb-10;
            }
        }
    }
    
    &-wrap {
        max-width: 900px;
        margin: auto;
    }

    &__icon {
        @apply w-2 h-2;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &::before,
        &::after {
            @apply bg-gray-700;
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            top: 50%;
            position: absolute;
            left: 50%;
            transition: transform .2s ease-in-out;
        }

        &:before {
            transform: translate3d(-50%,-50%,0) rotate(0deg);
        }

        &:after {
            transform: translate3d(-50%,-50%,0) rotate(90deg);
        }

        [open] & {
            &:after {
                transform: translate3d(-50%,-50%,0) rotate(0deg);
            }
        }
    }
}