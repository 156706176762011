.close {
    @apply h-5 w-5 block relative;

    span {
        @apply absolute w-full bg-gray-800;
        height: 2px;
        left: 50%;
        top: 50%;

        &:nth-child(1) {
            transform: translate3d(-50%, -50%, 0) rotate(40deg);
            
        }

        &:nth-child(2) {
            transform: translate3d(-50%, -50%, 0) rotate(-40deg);
        }
    }
}