.page-content {
    > * {
        &:not(:last-child) {
            @apply pb-6;

            @screen sm {
                @apply pb-12;
            }
            @screen md {
                @apply pb-16;
            }
            @screen lg {
                @apply pb-20;
            }
            @screen xl {
                @apply pb-24;
            }
        }
    }
}