.header {
    height: 4rem;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 900;
    left: 0;
    transition: height .2s ease-in-out;

    @screen lg {
        height: 5rem;
    }

    &:before {
        @apply bg-white;
        content: '';
        position: absolute;
        z-index: 0;
        width: calc(100% + 4rem);
        height: 100%;
        top: 0;
        left: 0;
        transform: translateX(-2rem);
    }

    &__inner {
        position: relative;
        z-index: 2;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__logo {
        position: absolute;
        display: block;
        width: auto;
        top: 50%;
        left: 50%;
        z-index: 20;
        transform: translate3d(-50%, -50%, 0);
    }

    &__menu {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        z-index: 10;
        top: 50%;
        display: inline-flex;
        right: 0;
        transform: translate3d(0,-50%,0);
    }

    &-reveal {
        @apply bg-white;
        z-index: -1;
        position: absolute;
        visibility: hidden;
        transition: transform .3s $cubicBezier 0s, visibility .0s ease .3s;

        @screen print {
            @apply hidden;
        }

        &.is-active {
            visibility: visible;
            transition: transform .3s $cubicBezier .0001s, visibility .0s ease .0s;
        }

        &--side {
            height: calc(100vh - 4rem);
            width: 100%;
            left: 100%;
            top: 100%;
            transform: translate3d(0,0,0);
            max-width: 400px;

            &.is-active {
                transform: translate3d(-100%,0,0);
            }

            @screen lg {
                height: calc(100vh - 5rem);
            }
        }

        &--full {
            bottom: 0;
            width: 100%;
            left: 0;
            height: calc(100vh - 4rem);
            transform: translate3d(0,0,0);
            opacity: 0;
            transition: transform .0001s $cubicBezier .21s, opacity .2s ease-in-out 0s, visibility .0s ease .3s;

            &.is-active {
                opacity: 1;
                transition: transform .0001s $cubicBezier 0s, opacity .2s ease-in-out .0001s, visibility .0s ease .0s;
                transform: translate3d(0,100%,0);
            }

            @screen lg {
                height: calc(100vh - 5rem);
            }
        }

        &--top {
            bottom: 0;
            width: 100%;
            left: 0;
            transform: translate3d(0,0,0);

            &.is-active {
                transform: translate3d(0,100%,0);
            }
        }

        &__inner {
            @apply py-4 relative h-full w-full;

            @screen lg {
                @apply py-8;
            }

            &--side {
                @apply px-4;

                @screen lg {
                    @apply px-6;
                }
            }
        }

        &__close {
            position: absolute;
            bottom: 2rem;
            left: 50%;
            opacity: 0;
            pointer-events: none;
            transform: translate3d(-50%,0,0);

            &:focus {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}
