.map {
    @apply w-full h-64 mb-6;

    @screen sm {
        @apply mb-10;
    }

    @screen md {
        @apply h-auto relative;

        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }

    &__frame {
        @apply w-full h-full;

        @screen md {
            @apply absolute block;
        }
    }
}