.lightbox {
    @apply fixed top-0 left-0 w-screen h-screen bg-white py-6 flex justify-center items-center;
    z-index: 99999999;
    transform: translate3d(0,0,0);
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s ease-in-out 0s, visibility .0s ease .3s;

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition: opacity .2s ease-in-out .0001s, visibility .0s ease .0s;
    }

    @screen lg {
        @apply pt-10;
    }

    &__inner {
        @apply block w-full h-full;
    }
}