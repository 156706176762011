/*

    Colour classes are all defined in TailwindCSS with classes such as 'bg-blue-200'.
    This is the list of colours used for this project to allow Sass to loop over themes and generate multiple themes. e.g. rows.

*/

// Configure which colors need black text after testing with an accessibility checker.
$lightBackgrounds: white, gray, chartreuse, background;

// Colour map used with color('primary')
$colors: (
    // Project colours
    chartreuse:     theme('colors.chartreuse.500'),
    blue:           theme('colors.blue.500'),

    background:     theme('colors.background'),

    // Core colours
    white:          theme('colors.white'),
    black:          theme('colors.gray.700'),
    gray:           theme('colors.gray.200'),
);

$social: (
    facebook:       theme('colors.facebook.500'),
    twitter:        theme('colors.twitter.500'),
    instagram:      theme('colors.instagram.500'),
    pinterest:      theme('colors.pinterest.500'),
);