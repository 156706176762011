.menu {
    &__icon {
        @apply h-8 w-8 flex items-center justify-center;
        border-radius: 50%;
        appearance: none;
        transition: color .2s ease-in-out;

        &--svg {
            @apply text-black;

            svg {
                transform: scale(1);
                transition: transform .1s $cubicBezier;
            }

            &:hover,
            &:focus,
            &.is-active {
                @apply text-chartreuse-500;
            }
        }

        &--hamburger {
            @apply absolute left-0;
            top: 50%;
            transform: translate3d(0,-50%,0);

            @screen md {
                @apply relative;
            }

            &:hover,
            &.is-active,
            &:focus {
                .hamburger {
                    span {
                        @apply bg-chartreuse-500;
                    }
                }
            }
        }
    }

    &-item {
        @apply relative;
        
        &--base {
            @apply border-solid border-t border-gray-800;

            &:last-child {
                > .menu-item__link {
                    @apply pb-1;
                }
            }
        }

        &__link {
            @apply block leading-tight;

            &--base {
                @apply py-2;
            }

            &--child {
                @apply text-gray-600 py-1;

                &:not(:last-child) {
                    // @apply mb-1;
                }
            }
        }
    }
}