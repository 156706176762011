[data-highlight-hover] {
    a {
        @apply transition-colors duration-200 ease-in-out;

        &:hover {
            @apply text-black #{!important};
        }
    }

    &.is-hovered {
        a {
            @apply text-gray-600;
        }
    }
}