.icon {
    * {
        fill: currentColor;
    }
    &--xs {
        width: 1rem;
        height: 1rem;

        @screen xs {
            width: 1.05rem;
            height: 1.05rem;
        }

        @screen sm {
            width: 1.2rem;
            height: 1.2rem;
        }

        @screen xl {
            width: 1.4rem;
            height: 1.4rem;
        }
    }
    &--sm {
        width: 1.2rem;
        height: 1.2rem;

        @screen xs {
            width: 1.5rem;
            height: 1.5rem;
        }
        
        @screen sm {
            width: 1.8rem;
            height: 1.8rem;
        }
    }
    &--md {
        width: 1.6rem;
        height: 1.6rem;

        @screen xs {
            width: 1.7rem;
            height: 1.7rem;
        }
        
        @screen lg {
            width: 2rem;
            height: 2rem;
        }
    }
    &--lg {
        width: 1.8rem;
        height: 1.8rem;

        @screen xs {
            width: 2.2rem;
            height: 2.2rem;
        }
        
        @screen sm {
            width: 3rem;
            height: 3rem;
        }
        
        @screen md {
            width: 3rem;
            height: 3rem;
        }
        
        @screen lg {
            width: 3.6rem;
            height: 3.6rem;
        }
    }
    &--xl {
        width: 4rem;
        height: 4rem;

        @screen xs {
            width: 4.2rem;
            height: 4.2rem;
        }
        
        @screen sm {
            width: 4.5rem;
            height: 4.5rem;
        }
        
        @screen md {
            width: 5rem;
            height: 5rem;
        }
        
        @screen lg {
            width: 6.5rem;
            height: 6.5rem;
        }
        
        @screen xl {
            width: 8rem;
            height: 8rem;
        }
    }

    &--logo {
        height: 2.5rem;
        width: 8rem;

        @screen xs {
            width: 10rem;
        }

        @screen lg {
            height: 3rem;
            width: 14rem;
        }
    }

    &--search {
        height: 1rem;
        width: 1rem;
    }

    &--basket {
        height: 1rem;
    }

    &--btn {
        height: .5rem;
        width: 1rem;
    }
}