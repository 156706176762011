.field {
    display: block;
    width: 100%;
    appearance: none;
    border: 0;
    padding: 0;

    + .field {
        @apply mt-4;
        
        @screen lg {
            @apply mt-6;
        }
    }

    @screen lg {
        &--align-right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }

        &--align-spread {
            flex: 1;
            display: flex;
            justify-content: space-between;
        }
    }

    &__label {
        @apply mb-2 text-black;
        display: block;

        &--hidden {
            visibility: hidden;
            margin-bottom: 0;
            height: 0;
        }
    }

    &__input {
        @apply border border-gray-700;
        width: 100%;
        background: transparent;
        padding: 0 1rem;
        height: 3rem;
        display: block;
        appearance: none;

        @screen lg {
            height: 3.125rem;
        }

        &:not(:placeholder-shown) {
            &:invalid {
                border: 1px solid color('warning');
                outline: none;
            }
        }

        &:focus {
            border: 1px solid color('primary');
            outline: none;
        }

        &--textarea {
            min-height: 6rem;
        }
    }

    &__select-wrap {
        position: relative;
        width: 100%;
        display: block;

        &:after {
            @apply border-gray-700;
            pointer-events: none;
            position: absolute;
            top: 50%;
            right: 1rem;
            display: block;
            height: .6rem;
            width: .6rem;
            transition: transform .2s ease-in-out, opacity .2s ease-in-out;
            transform: translate3d(.2rem,-60%,0) rotate(45deg);
            content: '';
            border-right: 2px solid;
            border-bottom: 2px solid;
        }
    }

    &__instructions {
        font-size: .8rem;
        line-height: 1.25;
        margin-top: .2rem;
    }

    &__error {
        font-size: .8rem;
        line-height: 1.1;
        margin-top: .2rem;
        color: color('red');
        font-weight: bold;
    }

    &__required {
        color: color('red');
    }

    &-options {
        &__option {
            margin-top: .5rem;
        }
    }
}