.breadcrumbs {
    > li {
        &:not(:first-child):not(:last-child) {
            display: none;

            @screen sm {
                display: block;
            }
        }
    }

    &__icon {
        @apply inline;
        margin-top: -1px;
    }
}