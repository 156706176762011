// *:-moz-focusring,
// button:focus,
// *:focus-visible,
// *:focus {
//     outline-offset: 2px;
//     outline-style: dotted !important;
//     outline-width: 1px !important;
//     outline-color: #1a1a1a !important;
// }

.js-focus-visible *:focus:not(.focus-visible) {
    outline: none !important;
}

.js-focus-visible .focus-visible {
        outline-offset: 2px;
        outline-style: dotted !important;
        outline-width: 1px !important;
        outline-color: #1a1a1a !important;
}