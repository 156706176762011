h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    @apply block text-black;
}

h1, .h1 {
    @apply font-medium leading-tight font-serif text-4xl;
}

h2,.h2 {
    @apply font-medium leading-tight font-serif text-3xl;
}

h3,.h3 {
    @apply font-medium leading-tight font-serif text-2xl;
}

h4,.h4 {
    @apply font-normal leading-none font-serif text-base;
}

h5,.h5 {
    @apply font-normal leading-none font-sans uppercase text-sm;
    letter-spacing: 2px;
}

h6,.h6 {
    @apply font-normal leading-none font-sans uppercase text-xs;
    letter-spacing: 2px;
}