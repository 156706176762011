.cartouche-title {
    @apply w-full;
    text-align: center;
    position: relative;
    z-index: 2;

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__inner {
        @apply p-8 pb-6;
        min-height: 8rem;
        position: relative;
        z-index: 2;
        height: 100%;
        width: 100%;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @screen xs {
            @apply p-10 pb-8;
        }

        @screen md {
            @apply p-12 pb-10;
        }

        @screen xl {
            min-height: 10rem;
        }
    }
}