body {
    @apply text-gray-700 leading-relaxed text-base font-serif;

    > * {
        @apply text-lg text-gray-700;
        text-indent: 0;
    }
}

.underline-collection-active {
    text-underline-offset: 3px;
    text-decoration: underline;
}

.engravers {
    @apply font-sans uppercase;
    letter-spacing: 2px;
}

.w-content {
    > * {
        @apply mb-4;

        @screen md {
            @apply mb-6;
        }

        @screen xl {
            @apply mb-8;
        }

        &:first-child {
            @apply mt-0;
        }

        &:last-child {
            @apply mb-0;
        }
    }

    h1,h2,h3,h4,h5,h6 {
        @apply mt-8;

        @screen xl {
            @apply mt-12;
        }
    }

    a {
        @apply text-gray-700;
        text-decoration: underline;
        transition: color .2s ease-in-out;
        text-underline-offset: 3px;
    }

	blockquote {
        @apply text-orange-500 border-orange-500 font-bold italic;
		padding: 1rem 2rem;
        margin-left: 0;
		border-left: 4px solid;
    }
    
    ul {
        &:not(.list) {
            li {
                @apply relative;

                &::before {
                    @apply absolute left-0 transform -translate-x-4;
                    content: '—';
                }
            }
        }
    }

    ol {
        &:not(.list) {
            list-style-type: count;
        }
    }

	ul, ol {
        &:not(.list) {
            @apply pl-4;

            li {
                @apply pl-3;

                &:not(:last-child) {
                    @apply mb-4;
                }
            }
        }
	}
}