@media print {
    *,
    *:before,
    *:after,
    *:first-letter,
    p:first-line,
    div:first-line,
    blockquote:first-line,
    li:first-line {
        background: transparent !important;
        color: black !important;
        box-shadow: none !important;
        text-shadow: none !important;
        text-align: left !important;
    }

    .container {
        @apply px-0 max-w-full #{!important};
    }

    a,
	a:visited {
        text-decoration: none;
    }

	// a[href]:not(.header__logo):after {
    //     content: " (" attr(href) ")";
    // }
    

    // Hide
    .footer,
    .print-hide,
    .btn,
    .slider__dots,
    .slide-slide:not(:first-child) {
        display: none !important;
    }

    // Show
    .print-show {
        display: block !important;
    }
    .print-grid {
        display: grid !important;
    }

    .site {
        padding: 1rem 0 0 0 !important;
        width: 100% !important;
    }
    
    .row {
        padding: 0 0 2rem 0 !important;
    }

    .slick-track {
        width: 100% !important;
    }

    .slick-list {
        height: auto !important;
    }

    .slick-slide {
        display: none !important;
    }

    .slick-slide:first-child {
        opacity: 1 !important;
        width: 100% !important;
        display: block !important;
    }

    body {
        width: 100% !important;
        min-width: 100% !important;
        height: 100% !important;
    }

    html {
        width: 100% !important;
    }

    .row {
        width: 100% !important;

        &:before {
            display: none !important;
        }
    }

    .print-product-details {
        width: 50% !important;
        max-width: 50% !important;
        vertical-align: top !important;
        display: inline-block !important;
        padding-left: 30px !important;
    }

    .print-product-image {
        width: 50% !important;
        max-width: 50% !important;
        vertical-align: top !important;
        display: inline-block !important;
    }
 }