.slider {
    &__dots {
        @apply h-3 left-0 bottom-0 mb-3 w-full relative;
        display: flex;
        list-style-type: none;
        z-index: 2;
        justify-content: flex-start;

        &--lightbox {
            @apply mb-0;
            justify-content: center;
        }

        li {
            &:not(:last-child) {
                margin-right: .5rem;
            }
        }

        button {
            @apply w-3 h-3 border-gray-800 border;
            background: transparent;
            appearance: none;
            padding: 0;
            display: block;
            border-radius: 50%;
            font-size: 0;
            transition: background .1s ease-in-out;

            &[aria-selected=true] {
                @apply bg-gray-700;
            }
        }
    }

    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }
}

.slick-dotted.slick-slider {
    margin: 0;
}

.slick-arrow {
    @apply w-3/12 text-gray-700 #{!important};
    height: calc(100% - 12px);
    z-index: 999;
    opacity: 0;
    top: 0;
    transform: translate3d(0,0,0);
    transition: opacity .2s ease-in-out;

    svg {
        @apply absolute;
    }

    &:before {
        display: none;
    }
}

.slick-next {
    @apply right-0;

    svg {
        @apply right-0 mr-4;
    }
}

.slick-prev {
    @apply left-0;

    svg {
        @apply left-0 ml-4;
    }
}



[data-slick=lightbox] {
    .slick-list {
        @apply w-full;
        height: calc(100% - 12px);
    }
    .slick-track {
        @apply h-full;
    }
    .slick-slide {
        > div {
            @apply h-full;
        }

        img {
            @apply w-full;
            height: auto;
            object-fit: contain;
            object-position: center;
            max-height: 100%;
        }

        .gallery__caption {
            text-align: center;
        }
    }
}