.hamburger {
    @apply h-4 w-5 block relative;

    span {
        @apply absolute w-full bg-black;
        height: 2px;
        left: 50%;
        top: 50%;
        transform-origin: center;
        transition: background .2s ease-in-out, opacity .2s ease-in-out, top .2s ease-in-out, transform .2s ease-in-out;

        &:nth-child(1) {
            top: 2px;
            transform: translate3d(-50%, -50%, 0);
            transition: background .2s ease-in-out, opacity .2s ease-in-out, top .2s ease-in-out .1s, transform .2s ease-in-out 0s;
        }

        &:nth-child(2) {
            transform: translate3d(-50%, -50%, 0);
        }

        &:nth-child(3) {
            top: calc(100% - 2px);
            transform: translate3d(-50%, -50%, 0);
            transition: background .2s ease-in-out, opacity .2s ease-in-out, top .2s ease-in-out .1s, transform .2s ease-in-out 0s;
        }
    }

    .is-active & {
        span {
            &:nth-child(1),
            &:nth-child(3) {
                top: 50%;
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(1) {
                transform: translate3d(-50%, -50%, 0) rotate(40deg);
                transition: background .2s ease-in-out, opacity .2s ease-in-out, top .2s ease-in-out 0s, transform .2s ease-in-out .1s;
                
            }

            &:nth-child(3) {
                transform: translate3d(-50%, -50%, 0) rotate(-40deg);
                transition: background .2s ease-in-out, opacity .2s ease-in-out, top .2s ease-in-out 0s, transform .2s ease-in-out .1s;
            }
        }
    }
}