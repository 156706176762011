.btn {
    $b: &;

    @apply text-black items-center flex-row inline-flex font-sans uppercase leading-none text-sm mr-2;
    letter-spacing: 2px;

    &:disabled {
        cursor: default;
    }

    @at-root {
        a#{&},
        button#{&} {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &--full {
        @apply w-full justify-center;
    }

    &--box,
    &--filled {
        @apply border-gray-700 border px-6;

        &.is-active,
        &.btn--step:hover {
            @apply underline;
            text-underline-position: under;
        }

        height: 3rem;
        display: inline-flex;
        align-items: center;
        transition: color .2s ease-in-out, background .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out;

        @screen sm {
            padding: 0 2rem;
        }
    
        @screen lg {
            @apply text-base px-12;
            height: 3.125rem;
        }
    }

    &--box {
        @apply bg-transparent;
        
        @at-root {
            a#{&},
            button#{&} {
                &:hover,
                &:focus {
                    @apply bg-black text-white;
                }
            }
        }
    }

    &--filled {
        @apply bg-black text-white;
        
        @at-root {
            a#{&},
            button#{&} {
                &:not(.btn--step) {
                    &:hover,
                    &:focus {
                        @apply bg-chartreuse-500 border-chartreuse-500 text-black;
                    }
                }
            }
        }
    }

    &--underline,
    &--plain {
        @apply text-xs;
        padding: .3rem 0;
    }

    &--underline {
        @apply border-b border-black;
    }

    &--plain {
        @apply border-transparent border-b;

        @at-root {
            a#{&},
            button#{&} {
                &.is-active,
                &:hover {
                    @apply border-black;
                }
            }
        }
    }

    svg {

    }
}