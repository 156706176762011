
// Tailwind
@tailwind base;
// @tailwind components;

// Core
@import '_core/**/*';

// Base
@import 'base/**/*';

// Typography
@import 'typography/**/*';

// Layout
@import 'layout/**/*';

// Components
@import 'components/**/*';

// Form
@import 'form/**/*';

// Elements
@import 'elements/**/*';