/*

    USE:
    
    @include clearfix;
    
*/

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}