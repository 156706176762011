.index-list {
    columns: 1;
    column-gap: 2rem;

    // Compensating for the horizontal spacing
    margin-bottom: -2rem;

    @screen xs {
        columns: 2;
    }

    @screen sm {
        columns: 3;
        margin-bottom: -3rem;
    }

    @screen md {
        columns: 4;
    }

    &__item {
        break-inside: avoid;
        margin-bottom: 2rem;
        page-break-inside: avoid;

        @screen sm {
            margin-bottom: 3rem;
        }
    }

    &__title {
        border-bottom: 1px solid;
        margin-bottom: .5rem;
        padding-bottom: .5rem;
    }
}